<template>
  <main v-if="storeData.length && salesHistory">
    <trac-back-button>Insights</trac-back-button>
    <div class="mt-3 text-base font-medium">Your Business at a glance</div>
    <div class="mt-6 flex items-center justify-between w-full">
      <div class="flex items-center">
        <div class="flex items-center">
          <svg
            class="w-4 h-4"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.68262 13.042V28.042H10.7139V21.4795V21.4795C10.7139 19.4084 12.2879 17.7295 14.2295 17.7295C16.1711 17.7295 17.7451 19.4084 17.7451 21.4795V21.4795V28.042H24.7764V13.042"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.0459 28.042H27.4131"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.23099 10.3282H1.23099C0.796712 11.2544 1.14853 12.3807 2.01679 12.8439C2.26109 12.9743 2.5305 13.0421 2.80365 13.042H25.6552C26.626 13.0423 27.4133 12.2031 27.4135 11.1676C27.4136 10.8762 27.3501 10.5888 27.2279 10.3282L23.8974 5.54199H4.56146L1.23099 10.3282Z"
              fill="#B4E6FF"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.56104 5.54102V3.66602L4.56104 3.66602C4.56104 2.63048 5.34803 1.79102 6.31885 1.79102H22.1392V1.79102C23.11 1.79102 23.897 2.63048 23.897 3.66602C23.897 3.66602 23.897 3.66602 23.897 3.66602V5.54102"
              stroke="#003283"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="ml-4">
            <trac-dropdown
              class="w-40"
              :choice="true"
              title="Select Store"
              :menuItems="getStore"
              v-model="store"
            >
            </trac-dropdown>
          </div>
        </div>
        <div class="flex ml-5 items-center">
          <div class="ml-4 flex items-center">
            <trac-dropdown
              class="w-40"
              :choice="true"
              title="Select Store"
              :menuItems="[
                {
                  name: '1W',
                },
                {
                  name: '1M',
                },
                {
                  name: '3M',
                },
                {
                  name: '6M',
                },
                {
                  name: '1Y',
                },
                {
                  name: 'MAX',
                },
              ]"
              v-model="time"
            >
            </trac-dropdown>
            <svg
              @click="toggle = !toggle"
              class="w-5 h-5 ml-3"
              :class="{
                'fill-current text-blue-500': time.name == 'CUSTOM',
              }"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.975586"
                y="2.43408"
                width="12.7281"
                height="12.2995"
                rx="1.65333"
                stroke="#253B95"
                stroke-width="1.23999"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.168 1.06738V3.8006"
                stroke="#253B95"
                stroke-width="1.23999"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.51079 1.06738V3.8006"
                stroke="#253B95"
                stroke-width="1.23999"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.975586 6.53404H13.7037"
                stroke="#253B95"
                stroke-width="1.23999"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="relative z-50">
              <!-- <date-picker
                ref="datepicker"
                v-model="custom"
                :inline="true"
                v-if="toggle"
                @change="handleChange"
                format="YYYY-MM-DD"
                class="absolute shadow-md curr"
                type="date"
              ></date-picker> -->
              <date-picker
                ref="datepicker"
                v-model="custom"
                :inline="true"
                v-if="toggle"
                @change="handleChange"
                format="YYYY-MM-DD"
                class="absolute shadow-md -ml-5 mt-3"
                range
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <trac-button
        class="hidden"
        @click.native="$router.push({ name: 'ViewInsights' })"
      >
        <span> Explore </span>
      </trac-button>
    </div>
    <div class="mt-5 p-6 insight-shadow rounded-md">
      <div class="two bg-accentLight rounded-md p-5">
        <div
          class="flex justify-between items-center pb-6 border-b border-gray-200"
        >
          <div>
            <p class="text-primaryGray tiny">
              {{ salsesChartFlag ? "Sales value" : "Sales volume" }}
            </p>
            <h2
              class="text-sm pl-3 tracking-wide font-medium"
              v-if="salsesChartFlag"
            >
              {{
                lastSalesDays(salsesChartFlag).reduce(
                  (total, sale) => total + sale,
                  0
                ) | formatPrice
              }}
            </h2>
            <h2 class="text-sm pl-3 tracking-wide font-medium" v-else>
              {{
                lastSalesDays(salsesChartFlag).reduce(
                  (total, sale) => total + sale,
                  0
                )
              }}
              Sales(s)
            </h2>
          </div>
          <div class="inline-flex items-center">
            <p class="text-primaryBlue tiny mr-4">Value</p>
            <trac-toggle
              :active="!salsesChartFlag"
              @newval="setupSalesGraph($event)"
            />
            <p class="text-primaryBlue tiny">Volume</p>
          </div>
        </div>
        <div class="mt-" v-if="activeSalesDays().length > 0">
          <zingchart
            id="zingchart-vue-18-graph-id0-scale_x-item_title"
            :height="280"
            :data="allchartData || {}"
          ></zingchart>
        </div>
        <div class="w-full h-full all-min" v-else>
          <trac-center-data>
            <p class="text-gray-500 pt-20">No sales made yet.</p>
          </trac-center-data>
        </div>
      </div>
    </div>
    <div class="mt-5 p-6 insight-shadow rounded-md flex">
      <div class="one">
        <div class="flex items-center">
          <div>
            <h1 class="uppercase text-sm font-normal">Best Selling products</h1>
            <svg
              width="20"
              height="4"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="-9.46895"
                  y1="2.21242"
                  x2="-7.84897"
                  y2="9.88217"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#06B6CB" />
                  <stop offset="1" stop-color="#253B95" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <svg
            class="w-5 h-5 ml-3 mx-1"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="13" r="13" fill="#253B95" fill-opacity="0.1" />
            <path
              d="M9.53336 14.1823L13.2557 18.1221L16.978 14.1823"
              stroke="#253B95"
              stroke-width="1.2688"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.2557 18.1221L13.2557 8.66674"
              stroke="#253B95"
              stroke-width="1.2688"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="getBestSellingProducts"
          class="mt-5 text-xs text-primaryGray uppercase"
        >
          #1 product
        </div>
        <div v-if="getBestSellingProducts" class="mt-2 flex items-center">
          <svg
            width="10"
            height="16"
            class="ml-3"
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.49922 12.8686C4.82836 12.8686 3.22593 12.2048 2.04445 11.0233C0.862967 9.84185 0.199219 8.23942 0.199219 6.56855C0.199219 4.89769 0.862967 3.29526 2.04445 2.11378C3.22593 0.932303 4.82836 0.268555 6.49922 0.268555C8.17008 0.268555 9.77251 0.932303 10.954 2.11378C12.1355 3.29526 12.7992 4.89769 12.7992 6.56855C12.7992 8.23942 12.1355 9.84185 10.954 11.0233C9.77251 12.2048 8.17008 12.8686 6.49922 12.8686ZM6.49922 9.71855C7.33465 9.71855 8.13587 9.38668 8.72661 8.79594C9.31735 8.2052 9.64922 7.40399 9.64922 6.56855C9.64922 5.73312 9.31735 4.93191 8.72661 4.34117C8.13587 3.75043 7.33465 3.41855 6.49922 3.41855C5.66379 3.41855 4.86257 3.75043 4.27183 4.34117C3.68109 4.93191 3.34922 5.73312 3.34922 6.56855C3.34922 7.40399 3.68109 8.2052 4.27183 8.79594C4.86257 9.38668 5.66379 9.71855 6.49922 9.71855ZM10.6992 12.6061V21.2686L6.49922 17.0686L2.29922 21.2686V12.6061C3.52995 13.4685 4.99637 13.9312 6.49922 13.9312C8.00207 13.9312 9.46848 13.4685 10.6992 12.6061Z"
              fill="#2C89D1"
            />
          </svg>
          <div class="ml-2 text-xs uppercase font-medium text-blue-500">
            {{ getBestSellingProducts ? getBestSellingProducts[0].name : 0 }}
          </div>
        </div>
        <div v-if="getBestSellingProducts" class="mt-4 all-min">
          <div
            v-for="(items, i) in getBestSellingProducts"
            class="border-b last:border-0 flex justify-between items-center"
            :key="i"
          >
            <div class="flex items-center py-2">
              <div class="h-8 w-8 border border-dashed border-gray-300"></div>
              <div class="text-xs ml-2">
                <div>{{ items.name }}</div>
                <div>{{ items.quantity }}</div>
              </div>
            </div>
            <div class="text-xs pr-3">{{ items.value }}</div>
          </div>
        </div>
        <div class="pt-12 all-min text-sm text-primaryGray" v-else>
          There is no available data to display
        </div>
      </div>
      <div class="two relative bg-accentLight rounded-md ml-4 p-6">
        <div>
          <h1 class="text-xs font-medium">Payments types</h1>
        </div>
        <div class="flex relative flex-wrap justify-between mt-5 mb-10">
          <div v-for="(chart, i) in doughnutChart" :key="i">
            <div class="flex items-center">
              <div :style="`background:${chart.color}`" class="sizer"></div>
              <div class="text-xs ml-2">{{ chart.label }}</div>
            </div>
          </div>
        </div>
        <vc-donut
          background="white"
          foreground="#B2E8FF"
          :size="200"
          unit="px"
          @section-mouseover="check"
          @section-mouseout="showTool = false"
          :thickness="40"
          :total="101"
          :start-angle="0"
          :sections="doughnutChart"
          :auto-adjust-text-size="true"
        >
          <h2 class="tiny tracking-wide font-medium">{{ highestValue }}</h2>
          <h2 class="very-tiny mt-1 font-light">
            As of {{ moment().format("ll") }}
          </h2>
        </vc-donut>
        <div
          v-if="showTool"
          :style="direction"
          class="absolute text-xs bg-white shadow px-4 py-2 z-50 border"
        >
          <div>{{ showToolValue.value }}%</div>
        </div>
      </div>
    </div>
    <div class="flex mt-5 w-full">
      <div class="insight-shadow flex-1 p-6 rounded-md">
        <div>
          <h1 class="uppercase text-sm font-normal">Your top customers</h1>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-9.46895"
                y1="2.21242"
                x2="-7.84897"
                y2="9.88217"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#06B6CB" />
                <stop offset="1" stop-color="#253B95" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          v-if="getBestSellingProducts != null"
          class="mt-5 text-xs text-primaryGray uppercase"
        >
          #1 Customer
        </div>
        <div
          v-if="getBestSellingProducts != null"
          class="mt-2 flex items-center"
        >
          <svg
            width="10"
            height="16"
            class="ml-3"
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.49922 12.8686C4.82836 12.8686 3.22593 12.2048 2.04445 11.0233C0.862967 9.84185 0.199219 8.23942 0.199219 6.56855C0.199219 4.89769 0.862967 3.29526 2.04445 2.11378C3.22593 0.932303 4.82836 0.268555 6.49922 0.268555C8.17008 0.268555 9.77251 0.932303 10.954 2.11378C12.1355 3.29526 12.7992 4.89769 12.7992 6.56855C12.7992 8.23942 12.1355 9.84185 10.954 11.0233C9.77251 12.2048 8.17008 12.8686 6.49922 12.8686ZM6.49922 9.71855C7.33465 9.71855 8.13587 9.38668 8.72661 8.79594C9.31735 8.2052 9.64922 7.40399 9.64922 6.56855C9.64922 5.73312 9.31735 4.93191 8.72661 4.34117C8.13587 3.75043 7.33465 3.41855 6.49922 3.41855C5.66379 3.41855 4.86257 3.75043 4.27183 4.34117C3.68109 4.93191 3.34922 5.73312 3.34922 6.56855C3.34922 7.40399 3.68109 8.2052 4.27183 8.79594C4.86257 9.38668 5.66379 9.71855 6.49922 9.71855ZM10.6992 12.6061V21.2686L6.49922 17.0686L2.29922 21.2686V12.6061C3.52995 13.4685 4.99637 13.9312 6.49922 13.9312C8.00207 13.9312 9.46848 13.4685 10.6992 12.6061Z"
              fill="#2C89D1"
            />
          </svg>
          <div class="ml-2 text-xs uppercase font-medium text-blue-500">
            {{
              getAllCustomerData != null
                ? getAllCustomerData[0].customerName
                : 0
            }}
          </div>
        </div>
        <div v-if="getAllCustomerData != null" class="mt-4 all-min">
          <div
            v-for="(customers, i) in getAllCustomerData"
            class="border-b last:border-0 flex justify-between items-center"
            :key="i"
          >
            <div class="flex items-center py-4">
              <div class="text-xs ml-2">
                <div>{{ customers.customerName }}</div>
              </div>
            </div>
            <div class="text-xs pr-3">
              {{ customers.totalPurchases | formatPrice }}
            </div>
          </div>
        </div>
        <div class="pt-12 all-min text-sm text-primaryGray" v-else>
          There is no available data to display
        </div>
      </div>
      <div class="insight-shadow p-5 flex-1 ml-5 rounded-md">
        <div>
          <h1 class="uppercase text-sm font-normal">New Customers</h1>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-9.46895"
                y1="2.21242"
                x2="-7.84897"
                y2="9.88217"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#06B6CB" />
                <stop offset="1" stop-color="#253B95" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          v-if="getBestSellingProducts != null"
          class="mt-5 text-xs text-primaryGray uppercase"
        >
          MOST RECENT
        </div>
        <div
          v-if="getBestSellingProducts != null"
          class="mt-2 flex items-center"
        >
          <svg
            width="10"
            height="16"
            class="ml-3"
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.49922 12.8686C4.82836 12.8686 3.22593 12.2048 2.04445 11.0233C0.862967 9.84185 0.199219 8.23942 0.199219 6.56855C0.199219 4.89769 0.862967 3.29526 2.04445 2.11378C3.22593 0.932303 4.82836 0.268555 6.49922 0.268555C8.17008 0.268555 9.77251 0.932303 10.954 2.11378C12.1355 3.29526 12.7992 4.89769 12.7992 6.56855C12.7992 8.23942 12.1355 9.84185 10.954 11.0233C9.77251 12.2048 8.17008 12.8686 6.49922 12.8686ZM6.49922 9.71855C7.33465 9.71855 8.13587 9.38668 8.72661 8.79594C9.31735 8.2052 9.64922 7.40399 9.64922 6.56855C9.64922 5.73312 9.31735 4.93191 8.72661 4.34117C8.13587 3.75043 7.33465 3.41855 6.49922 3.41855C5.66379 3.41855 4.86257 3.75043 4.27183 4.34117C3.68109 4.93191 3.34922 5.73312 3.34922 6.56855C3.34922 7.40399 3.68109 8.2052 4.27183 8.79594C4.86257 9.38668 5.66379 9.71855 6.49922 9.71855ZM10.6992 12.6061V21.2686L6.49922 17.0686L2.29922 21.2686V12.6061C3.52995 13.4685 4.99637 13.9312 6.49922 13.9312C8.00207 13.9312 9.46848 13.4685 10.6992 12.6061Z"
              fill="#2C89D1"
            />
          </svg>
          <div class="ml-2 text-xs uppercase font-medium text-blue-500">
            {{ getNewCustomers }}
          </div>
        </div>
        <!-- <div v-if="getAllCustomerData != null" class="mt-4 all-min">
          <div
            v-for="(customers, i) in getAllCustomerData"
            class="border-b last:border-0 flex justify-between items-center"
            :key="i"
          >
            <div class="flex items-center py-4">
              <div class="text-xs ml-2">
                <div>{{ customers.customerName }}</div>
              </div>
            </div>
            <div class="text-xs pr-3">
              {{ customers.totalPurchases | formatPrice }}
            </div>
          </div>
        </div>
        <div class="pt-12 all-min text-sm text-primaryGray" v-else>
          There is no available data to display
        </div> -->
      </div>
    </div>
    <div class="flex mt-5 w-full">
      <div class="insight-shadow flex-1 p-6 rounded-md">
        <div>
          <h1 class="uppercase text-sm font-normal">Inventory</h1>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-9.46895"
                y1="2.21242"
                x2="-7.84897"
                y2="9.88217"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#06B6CB" />
                <stop offset="1" stop-color="#253B95" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          v-if="getTopInventoryData != null"
          class="mt-5 text-xs text-primaryGray uppercase"
        >
          Inventory Value
        </div>
        <div v-if="getTopInventoryData != null" class="mt-2 flex items-center">
          <div class="ml-2 text-xs uppercase font-medium text-gray-900">
            {{ inventoryData.totalInventoryValue | formatPrice }}
          </div>
        </div>
        <div v-if="getAllCustomerData != null" class="mt-4 all-min">
          <div
            v-for="(inventory, i) in getTopInventoryData"
            class="border-b last:border-0 flex justify-between items-center"
            :key="i"
          >
            <div class="flex items-center py-4">
              <div class="text-xs ml-2">
                <div>{{ inventory.name }}</div>
              </div>
            </div>
            <div class="text-xs pr-3">
              {{ inventory.value | formatPrice }}
            </div>
          </div>
        </div>
        <div class="pt-12 all-min text-sm text-primaryGray" v-else>
          There is no available data to display
        </div>
      </div>
      <div class="insight-shadow p-5 flex-1 ml-5 rounded-md">
        <div>
          <h1 class="uppercase text-sm font-normal">Low stock items</h1>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-9.46895"
                y1="2.21242"
                x2="-7.84897"
                y2="9.88217"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#06B6CB" />
                <stop offset="1" stop-color="#253B95" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          v-if="getLowInventoryData != null"
          class="mt-5 text-xs text-primaryGray uppercase"
        >
          Lowest In Stock
        </div>
        <div
          v-if="getLowInventoryData != null"
          class="mt-2 flex items-center justify-between"
        >
          <div class="ml-2 text-xs uppercase font-medium text-red-500">
            {{ getLowInventoryData[0].name }}
          </div>
          <div class="text-xs text-primaryGray uppercase pr-5">Units left</div>
        </div>
        <div v-if="getAllCustomerData != null" class="mt-4 all-min">
          <div
            v-for="(inventory, i) in getLowInventoryData"
            class="border-b last:border-0 flex justify-between items-center"
            :key="i"
          >
            <div class="flex items-center py-4">
              <div class="text-xs ml-2">
                <div>{{ inventory.name }}</div>
              </div>
            </div>
            <div class="text-xs pr-5 mr-5">
              {{ inventory.quantity }}
            </div>
          </div>
        </div>
        <div class="pt-12 all-min text-sm text-primaryGray" v-else>
          There is no available data to display
        </div>
      </div>
    </div>
  </main>
  <div class="w-full h-screen" v-else>
    <trac-loading />
  </div>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_LOCAL_DB_DATA,
  GET_USER_DATA,
} from "../../browser-db-config/localStorage";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  data() {
    return {
      time: { name: "1W" },
      custom: null,
      toggle: false,
      showTool: false,
      showToolValue: null,
      storeData: [],
      showToolPositionY: null,
      showToolPositionX: null,
      store: { name: "all", _id: "all" },
      salsesChartFlag: false,
      chartData: {},
      activeSalesHistoryDate: "today",
      activeTwo: "invoice",
      moment,
      user: GET_USER_DATA(),
      stores: null,
      customers: null,
      salesHistory: null,
      salesDate: new Date(),
      invoices: null,
      invoiceDue: {
        over30Days: [],
        under30Days: [],
        overdue: [],
      },
      dateRange: {
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      salesStats: [],
      customersStats: [],
      inventoryStats: [],
      products: null,
      customersData: null,
      inventoryData: null,
    };
  },
  components: { DatePicker },
  watch: {
    salesStats(x) {
      let k = [...this.sectionsOptions];
      if (x.length > 0) {
        k[0].value = 50;
        k[1].value = 40;
        k[2].value = 10;
      } else {
        k[0].value = 0;
        k[1].value = 0;
        k[2].value = 0;
      }
    },
    store(x) {
      if (x) {
        this.callFunctions();
      }
    },
    time: {
      immediate: true,
      handler(x) {
        // (x)
        if (x.name) {
          switch (x.name) {
            case "1W":
              this.dateRange.startDate = moment()
                .subtract(7, "d")
                .format("YYYY-MM-DD");
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            case "1M":
              this.dateRange.startDate = moment()
                .subtract(1, "months")
                .format("YYYY-MM-DD");
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            case "3M":
              this.dateRange.startDate = moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD");
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            case "6M":
              this.dateRange.startDate = moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD");
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            case "1Y":
              this.dateRange.startDate = moment()
                .subtract(1, "years")
                .format("YYYY-MM-DD");
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            case "MAX":
              this.dateRange.startDate = "2017-01-01";
              this.dateRange.endDate = moment().format("YYYY-MM-DD");
              this.callFunctions();
              break;
            // case 'CUSTOM':
            //   if (this.custom !== null) {
            //     this.dateRange.startDate = moment(this.custom).format('YYYY-MM-DD')
            //     this.dateRange.endDate = moment(this.custom).format('YYYY-MM-DD')
            //     this.callFunctions()
            //   }
            //   break;
            default:
              break;
          }
        }
      },
    },
  },

  computed: {
    direction() {
      // (this.showToolPositionY, 'yooooooouuuuuu')
      if (this.showTool && this.showToolPositionY > 500) {
        let b = {
          top: this.showToolPositionY - 400 + "px",
          left: this.showToolPositionX - 850 + "px",
        };
        return b;
      } else {
        let b = {
          top: this.showToolPositionY - 200 + "px",
          left: this.showToolPositionX - 850 + "px",
        };
        return b;
      }
    },
    getTopInventoryData() {
      if (!!this.inventoryData) {
        return this.inventoryData.topInventoryItems.slice(0, 5);
      }
    },
    getLowInventoryData() {
      if (!!this.inventoryData) {
        return this.inventoryData.lowInventoryItems
          .sort((a, b) => a.quantity - b.quantity)
          .slice(0, 5);
      }
    },
    getBestSellingProducts() {
      if (!!this.products) {
        return this.products.topSellingItems.slice(0, 5);
      }
    },
    getAllCustomerData() {
      if (!!this.customersData) {
        return this.customersData.topSellingCustomers.slice(0, 5);
      }
    },
    getNewCustomers() {
      if (!!this.customersData) {
        return this.customersData.newCustomer.customerName;
      }
    },
    doughnutChart() {
      if (!!this.products) {
        let k = this.products.paymentStats.paymentTypePercent;
        let u = [
          {
            label: "Bank Transfer",
            value: k.bank_transfer || 0,
            color: "#06B6CB",
          },
          { label: "Card", value: k.card || 0, color: "#1E67BB" },
          { label: "Cash", value: k.cash || 0, color: "#809DCB" },
          { label: "Pos", value: k.pos || 0, color: "#B2E7FF" },
          {
            label: "Own Bank Account",
            value: k.own_bank_account,
            color: "#253B95",
          },
        ];
        // (u, 'likekekkekekeke')
        return u;
      } else {
        return [];
      }
    },
    highestValue() {
      if (this.doughnutChart.length) {
        let n = this.doughnutChart.sort((a, b) => b.value - a.value);
        // (n, 'spag')
        return n[0].label;
      } else {
        return null;
      }
    },
    allchartData() {
      if (this.activeSalesDays()) {
        return (this.chartData = {
          type: "area",
          backgroundColor: "none",
          scaleY: {
            lineColor: "none",
            tick: {
              visible: false,
            },
            guide: {
              visible: false,
            },
            item: {
              visible: false,
            },
          },
          scaleX: {
            label: {
              /* Add a scale title with a label object. */
              text: `Sales Margins from ${this.activeSalesDays()[0]} to ${
                this.activeSalesDays()[this.activeSalesDays().length - 1]
              }`,
            },
            /* Add your scale labels with a labels array. */
            labels: this.activeSalesDays(),
          },
          series: [
            {
              values: this.lastSalesDays(this.salsesChartFlag),
              "background-color": "#07b2c9 #234398",
              "alpha-area": 0.2,
              "line-color": "#253B95",
              marker: {
                "background-color": "#ffffff" /* hexadecimal or RGB value */,
                size: 4 /* in pixels */,
                "border-color": "#253b95" /* hexadecimal or RBG value */,
                "border-width": 2 /* in pixels */,
              },
            },
          ],
        });
      }
    },
    getStore() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });
      let v = [{ name: "all", _id: "all" }, ...u];
      return v;
    },
    summationOfAllInvoices() {
      return this.invoices.reduce((total, inv) => {
        return (total += inv.items.reduce(
          (subTotal, item) => subTotal + item.quantity * item.unit_price,
          0
        ));
      }, 0);
    },
    recentSalesHistory() {
      return this.salesHistory.filter((sale) => {
        return (
          moment(sale.created_at).format("YYYY-MM-DD") ===
          moment(this.salesDate).format("YYYY-MM-DD")
        );
      });
    },
    salesHistorySummation() {
      return this.recentSalesHistory.reduce((total, sale) => {
        return (
          total +
          sale.items.reduce((subTotal, item) => {
            return subTotal + item.quantity * item.price;
          }, 0)
        );
      }, 0);
    },
    totalProductsSoldSoFar() {
      const countedProducts = [];
      this.recentSalesHistory.forEach((sale) => {
        sale.items.forEach((item) => {
          if (!countedProducts.includes(item._id)) {
            countedProducts.push(item._id);
          }
        });
      });
      return countedProducts.length;
    },
    recentInvoices() {
      return this.invoices.slice(0, 3);
    },
    openedInvoices: function () {
      return this.invoices.filter((invoice) => invoice.opened);
    },
    overdueInv() {
      let amount = 0;
      this.invoiceDue.overdue.forEach((inv) => {
        amount += inv.items.reduce((total, item) => {
          return total + item.quantity * item.unit_price;
        }, 0);
      });
      return amount;
    },
    overdueUnder30Days() {
      let amount = 0;
      this.invoiceDue.under30Days.forEach((inv) => {
        amount += inv.items.reduce(
          (total, item) => total + item.quantity * item.unit_price,
          0
        );
      });
      return amount;
    },
    overdueOver30Days() {
      let amount = 0;
      this.invoiceDue.over30Days.forEach((inv) => {
        amount += inv.items.reduce(
          (total, item) => total + item.quantity * item.unit_price,
          0
        );
      });
      return amount;
    },
    invoiceDueForToday() {
      let amount = 0;
      this.invoiceDue.invoiceForToday.forEach((inv) => {
        amount += inv.items.reduce(
          (total, item) => total + item.quantity * item.unit_price,
          0
        );
      });
      return amount;
    },
    sectionsOptions() {
      this.invoiceDue.over30Days = this.openedInvoices.filter((invoice) => {
        return (
          moment().diff(moment(invoice.due_date), "days") > 30 && invoice.opened
        );
      });
      this.invoiceDue.under30Days = this.openedInvoices.filter(
        (invoice, index) => {
          return (
            moment().diff(moment(invoice.due_date), "days") <= 30 &&
            invoice.opened
          );
        }
      );
      this.invoiceDue.overdue = this.openedInvoices.filter((invoice) => {
        return (
          // moment().diff(moment(invoice.due_date), "days") >= 1 &&
          invoice.opened
        );
      });
      this.invoiceDue.invoiceForToday = this.openedInvoices.filter(
        (invoice) => {
          return (
            new Date(invoice.due_date).toDateString() ===
              new Date().toDateString() && invoice.opened
          );
        }
      );

      const sum =
        this.invoiceDue.over30Days.length +
        this.invoiceDue.under30Days.length +
        this.invoiceDue.overdue.length;

      return [
        {
          label: "All due invoices.",
          value: (this.invoiceDue.overdue.length / sum) * 100,
          color: "#253B95",
        },
        {
          label: "Invoice due for over 30 days.",
          value: (this.invoiceDue.over30Days.length / sum) * 100,
          color: "#1E67BB",
        },
        {
          label: "Invoice due for less than 30 days.",
          value: (this.invoiceDue.under30Days.length / sum) * 100,
          color: "#06B6CB",
        },
      ];
    },
  },

  methods: {
    check(x, y) {
      this.showTool = true;
      this.showToolValue = x;
      this.showToolPositionY = y.screenY;
      this.showToolPositionX = y.clientX;
    },
    openCalender() {
      if (!this.toggle) {
        this.time.name = "CUSTOM";
        this.toggle = !this.toggle;
      } else {
        this.time.name = null;
        this.toggle = !this.toggle;
      }
    },
    handleChange(x, y) {
      if (x || y) {
        this.dateRange.startDate = moment(this.custom[0]).format("YYYY-MM-DD");
        this.dateRange.endDate = moment(this.custom[1]).format("YYYY-MM-DD");
        this.callFunctions();
        this.toggle = false;
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      let res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.storeData = res.data || {};
      }
    },
    callFunctions() {
      this.fetchSalesHistory();
      this.setupSalesGraph(this.salsesChartFlag);
      this.fetchProducts();
      this.fetchCustomer();
      this.fetchInventoryData();
    },
    async fetchProducts() {
      let payload = {};
      payload.startDate = this.dateRange.startDate;
      payload.endDate = this.dateRange.endDate;
      payload.id = this.store._id;
      let res = await this.$store.dispatch("GET_PRODUCTS_DATA", payload);
      if (res.status) {
        this.products = res.data || null;
      }
    },
    async fetchCustomer() {
      let payload = {};
      payload.startDate = this.dateRange.startDate;
      payload.endDate = this.dateRange.endDate;
      payload.id = this.store._id;
      let res = await this.$store.dispatch("GET_CUSTOMER_DATA", payload);
      if (res.status) {
        this.customersData = res.data || null;
      }
    },
    async fetchInventoryData() {
      let payload = {};
      payload.startDate = this.dateRange.startDate;
      payload.endDate = this.dateRange.endDate;
      payload.id = this.store._id;
      let res = await this.$store.dispatch("GET_INVENTORY_DATA", payload);
      if (res.status) {
        this.inventoryData = res.data || null;
      }
    },

    async fetchSalesHistory() {
      const payload = {
        _id: this.store._id,
      };
      await this.$store.dispatch("FETCH_SALES_HISTORY", payload);
      let res = this.$store.getters["GET_ALL_SALES_HISTORY"];
      if (res.status) {
        this.salesHistory = res.data ? res.data.salesHistory || [] : [];
        // Sort sales
        this.salesHistory = this.salesHistory.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        );
      }
    },
    handleSectionClick() {},
    fetchAllCustomers: async function () {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        this.customers = res.data || [];
      }
    },
    lastSalesDays(val) {
      let prevSaleDate = new Date();
      let prevSaleItems = 0;
      const datesSalesItems = [];

      this.salesHistory.forEach((sale, index) => {
        if (
          new Date(prevSaleDate).toLocaleDateString() ===
          new Date(sale.created_at).toLocaleDateString()
        ) {
          // Add to that day's sales
          prevSaleItems += val
            ? sale.items
              ? sale.items.reduce(
                  (total, item) => item.quantity * item.price + total,
                  0
                )
              : 0
            : 1;
          // prevSaleItems += sale.items.length;

          if (index + 1 === this.salesHistory.length) {
            datesSalesItems.push(prevSaleItems);
          }
        } else if (index === 0) {
          prevSaleItems = val
            ? sale.items
              ? sale.items.reduce(
                  (total, item) => item.quantity * item.price + total,
                  0
                )
              : 0
            : 1;
          // prevSaleItems = sale.items.length;
        } else {
          datesSalesItems.push(prevSaleItems);
          // Add new sales
          prevSaleItems = val
            ? sale.items
              ? sale.items.reduce(
                  (total, item) => item.quantity * item.price + total,
                  0
                )
              : 0
            : 1;
          // prevSaleItems = sale.items.length;

          if (index + 1 === this.salesHistory.length) {
            datesSalesItems.push(prevSaleItems);
          }
        }
        prevSaleDate = sale.created_at;
      });
      return datesSalesItems.slice(0, 7).reverse();
    },
    activeSalesDays() {
      if (this.salesHistory) {
        const data = this.salesHistory.map((sale) => {
          return moment(sale.created_at).format("DD MMM");
        });
        return [...new Set(data)].slice(0, 7).reverse();
      }
    },
    setSaleHistoryDate(date) {
      this.activeSalesHistoryDate = date;
      if (this.activeSalesHistoryDate === "today") {
        this.salesDate = new Date();
      } else {
        this.salesDate = this.salesDate.setDate(this.salesDate.getDate() - 1);
      }
    },

    // Invoices
    async fetchAllInvoices() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      const res = this.$store.getters["GET_ALL_INVOICES"] || [];
      if (res.status) {
        const inv = res.data ? res.data.invoice || [] : [];
        this.invoices = inv.sort((a, b) =>
          a.created_at < b.created_at ? 1 : -1
        );

        this.invoices.forEach((eachInv) => {
          this.customers.forEach((customer) => {
            if (eachInv.client === customer._id) {
              eachInv.customer_name =
                customer.first_name + " " + customer.last_name;
            }
          });
        });
      }
    },

    // formatPrice(value) {
    //   let val = (value / 1).toFixed(2).replace(",", ".");
    //   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },

    // Setup sales graph
    setupSalesGraph(val) {
      if (this.activeSalesDays()) {
        this.salsesChartFlag = val;
        this.chartData = {
          type: "area",
          backgroundColor: "none",
          scaleY: {
            lineColor: "none",
            tick: {
              visible: false,
            },
            guide: {
              visible: false,
            },
            item: {
              visible: false,
            },
          },
          scaleX: {
            label: {
              /* Add a scale title with a label object. */
              text: `Sales Margins from ${this.activeSalesDays()[0]} to ${
                this.activeSalesDays()[this.activeSalesDays().length - 1]
              }`,
            },
            /* Add your scale labels with a labels array. */
            labels: this.activeSalesDays(),
          },
          series: [
            {
              values: this.lastSalesDays(val),
              "background-color": "#07b2c9 #234398",
              "alpha-area": 0.2,
              "line-color": "#253B95",
              marker: {
                "background-color": "#ffffff" /* hexadecimal or RGB value */,
                size: 4 /* in pixels */,
                "border-color": "#253b95" /* hexadecimal or RBG value */,
                "border-width": 2 /* in pixels */,
              },
            },
          ],
        };
      }
    },
  },
  async created() {
    await this.fetchAllStores();
    await this.fetchSalesHistory();
    await this.fetchProducts();
    await this.fetchCustomer();
    await this.fetchInventoryData();
    // await this.fetchAllCustomers();
    // await this.fetchAllInvoices();
    await this.setupSalesGraph(this.salsesChartFlag);
  },
};
</script>

<style>
.all-min {
  min-height: 280px;
}
.insight-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.one {
  flex: 1.3;
}
.two {
  flex: 1;
}
.sizer {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>
